import React, {useCallback, useRef, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';
import diamondImage from '../../images/about-progress-diamond.svg';
import triangleImage from '../../images/about-progress-triangle.svg';
import useScroll from 'ahooks/lib/useScroll';
import useSize from 'ahooks/lib/useSize';
import LeftArrow from '../../svgs/left-arrow';

type PropTypes = {};
const TIMELINE_DATA = [
  {
    time: '2018.6',
    event: ['基于机器视觉的', '焊接机器人导航'],
  },
  {
    time: '2019.6',
    event: ['基于X光轮胎缺陷检测'],
  },
  {
    time: '2020.6',
    event: ['基于机构光高精度', '实时动态3D重建与测量'],
  },
  {
    time: '2021.1',
    event: ['公司成立'],
  },
  {
    time: '2021.5',
    event: ['上海理工大学光电信息学院', '建立合作'],
  },
  {
    time: '2021.8',
    event: ['推出高性能工业读码器'],
  },
  {
    time: '2021.11',
    event: ['完成千万级天使轮融资'],
  },
  {
    time: '2022.6',
    event: ['正式发布并量产', '高性能工业读码器'],
  },
];
const MILESTONE_WIDTH = 170;
const DECORATION_WIDTH = 38;
const ARROW_SIZE = 40;

export default function SectionProgress({}: PropTypes) {
  const containerWidth =
    MILESTONE_WIDTH * TIMELINE_DATA.length +
    DECORATION_WIDTH * (TIMELINE_DATA.length - 1);
  const {
    containerRef,
    timelineRef,
    shouldShowPrev,
    shouldShowNext,
    scrollBy,
    arrowOffset,
  } = useHorizontalScroll();
  return (
    <section className={styles.section}>
      <HfTitle className={styles.title} title="PROGRESS" subTitle="大事记" />
      <div className={styles.timeline}>
        <div className={styles.line} />
        <div
          className={clsx(styles.prev, shouldShowPrev && styles.show)}
          style={{left: arrowOffset}}
          onClick={scrollBy(-MILESTONE_WIDTH - DECORATION_WIDTH)}
        >
          <LeftArrow className={styles.icon} />
        </div>
        <div
          className={clsx(styles.next, shouldShowNext && styles.show)}
          style={{right: arrowOffset}}
          onClick={scrollBy(MILESTONE_WIDTH + DECORATION_WIDTH)}
        >
          <LeftArrow className={styles.icon} />
        </div>
        <div className={styles.content} ref={containerRef}>
          <div
            className={styles.container}
            ref={timelineRef}
            style={{width: containerWidth}}
          >
            {TIMELINE_DATA.map((item, index) => (
              <>
                <div className={styles.event} style={{width: MILESTONE_WIDTH}}>
                  <div className={styles.time}>{item.time}</div>
                  <div className={styles.diamond}>
                    <img src={diamondImage} alt="" />
                  </div>
                  <div className={styles.description}>
                    {item.event.map(text => (
                      <div>{text}</div>
                    ))}
                  </div>
                </div>
                {index !== TIMELINE_DATA.length - 1 && (
                  <div
                    className={styles.decoration}
                    style={{width: DECORATION_WIDTH}}
                  >
                    <img src={triangleImage} alt="" />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function useHorizontalScroll() {
  const isSSR = typeof window === 'undefined';
  const containerRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const containerPosition = useScroll(containerRef);
  const timelinePosition = useScroll(timelineRef);
  const containerSize = useSize(containerRef);
  const timelineSize = useSize(timelineRef);
  let windowSize = {width: 0, height: 0};
  if (!isSSR) {
    windowSize = useSize(document.body) || windowSize;
  }
  let shouldShowPrev = false;
  let shouldShowNext = false;
  let arrowOffset = 0;
  if (
    containerPosition &&
    timelinePosition &&
    containerSize &&
    timelineSize &&
    windowSize
  ) {
    shouldShowPrev = containerPosition.left > 0;
    shouldShowNext =
      containerPosition.left + containerSize.width !== timelineSize.width;
    arrowOffset = (windowSize.width - containerSize.width) / 2 - ARROW_SIZE;
  }
  function scrollBy(offsetLeft: number) {
    return () => {
      if (!containerRef.current) return;
      containerRef.current.scrollBy({
        left: offsetLeft,
        behavior: 'smooth',
      });
    };
  }
  return {
    containerRef,
    timelineRef,
    shouldShowPrev,
    shouldShowNext,
    scrollBy,
    arrowOffset,
  };
}
