import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';
import layoutImage from '../../images/about-layout.jpg';
import scannerImage from '../../images/icon-scanner.svg';
import cameraImage from '../../images/icon-camera.svg';
import checkImage from '../../images/icon-check.svg';
import {GAP_SIZE_3, GAP_SIZE_BASE} from '../../constants';

type PropTypes = {};
const PRODUCTS_DATA = [
  {
    icon: scannerImage,
    iconKey: 'scanner',
    description:
      '1. 推出具有环境自适应功能的工业读码器。极大的方便各种多变现场环境下的安装和调试。获得最优读码性能。',
  },
  {
    icon: cameraImage,
    iconKey: 'camera',
    description:
      '2. 首次推出高速3D智能相机。从硬件层开发高效的3D点云处理与分析的嵌入式算法。适用物流行业的高速分拣系统。 ',
  },
  {
    icon: checkImage,
    iconKey: 'check',
    description:
      '3. 开发针对轮胎X光图像特征的缺陷检测深度卷积网络模型；由于深度学习的训练数据量要求百万数量级，设计检测模型时，通过可迁移学习提高初期的检测率；开发针对轮胎缺陷特征的损失函数提高缺陷检测率。',
  },
];

export default function SectionProductLayout({}: PropTypes) {
  return (
    <section className={styles.section}>
      <HfTitle
        className={styles.title}
        title="PRODUCT LAYOUT"
        subTitle="产品规划布局"
      />
      <div className={styles.layout}>
        <img src={layoutImage} alt="" />
      </div>
      <div className={styles.products}>
        <div className={styles.content}>
          <Row justify="space-between" align="top" gutter={[0, GAP_SIZE_BASE]}>
            {PRODUCTS_DATA.map(product => (
              <Col sm={8} span={24}>
                <div className={styles.icon}>
                  <img className={styles[product.iconKey]} src={product.icon} />
                </div>
                <div className={styles.description}>{product.description}</div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
}
