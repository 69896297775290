// extracted by mini-css-extract-plugin
export var camera = "index-module--camera--rYjDV";
export var check = "index-module--check--JLGce";
export var content = "index-module--content--TrxXQ";
export var description = "index-module--description--pI-yj";
export var icon = "index-module--icon--e2VvG";
export var layout = "index-module--layout--8mh-M";
export var products = "index-module--products--+mpih";
export var scanner = "index-module--scanner--z0Vao";
export var section = "index-module--section--12ERh";
export var title = "index-module--title--gnoJh";