import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';

type PropTypes = {};

export default function HfAboutHero({}: PropTypes) {
  return (
    <div className={styles.hero}>
      <StaticImage
        className={styles.bg}
        src="../../images/about-hero.jpg"
        alt=""
      />
      <div className={styles.textBox}>
        <div className={styles.placeholder} />
        <div className={styles.content}>
          <div className={styles.title}>
            <div>唯视智能Heyfocus</div>
          </div>
          <div className={styles.description}>
            一家以振兴中国机器视觉和传感科技为使命的创新型高科技公司，由国内知名自动化行业领袖，以及知名的人工智能领域专家陈胜教授，携手一批极具产业报国情怀的中国资深自动化人所创立。
          </div>
        </div>
      </div>
    </div>
  );
}
