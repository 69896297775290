import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';

type PropTypes = {};

export default function SectionCulture({}: PropTypes) {
  return (
    <section className={styles.section}>
      <HfTitle
        className={styles.title}
        title="CORPORATE CULTURE"
        subTitle="企业文化"
      />
      <div className={styles.row}>
        <div className={clsx(styles.content)}>
          <li>使命</li>
          <div className={styles.description}>
            让机器视觉与人工智能惠及所有自动化行业，帮助企业实现智能化、自动化检测，降低成本切实提升企业产品质量和生产效率。
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={clsx(styles.content)}>
          <li>价值观</li>
          <div className={styles.description}>
            用心对待客户诉求，用心构建生态体系，乐观面对挑战、变化和未知。
          </div>
        </div>
      </div>
    </section>
  );
}
