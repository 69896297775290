import React from 'react';
import {graphql, PageProps} from 'gatsby';
import DefaultLayout from '../layouts/default';
import HfAboutHero from '../components/hf-about-hero';
import * as styles from './about.module.scss';
import SectionProgress from '../components/section-progress';
import SectionCulture from '../components/section-culture';
import SectionProductLayout from '../components/section-product-layout';
import SectionCoreTeam from '../components/section-core-team';
import SectionHonors from '../components/section-honors';
import HfArticleToc from '../components/hf-article-toc';
import HfSeo from '../components/hf-seo';

const TABS = [
  {
    title: '大事记',
    anchor: 'about-1',
  },
  {
    title: '企业文化',
    anchor: 'about-2',
  },
  {
    title: '产品规划布局',
    anchor: 'about-3',
  },
  {
    title: '核心团队',
    anchor: 'about-4',
  },
  // {
  //   title: '荣誉&证书',
  //   anchor: 'about-5',
  // },
];

const AboutPage = ({data, location}: PageProps) => {
  return (
    <DefaultLayout location={location}>
      <HfAboutHero />
      <HfArticleToc tabs={TABS} />
      <section className={styles.overview}>
        <p>
          唯视拥有经验丰富的技术专家团队和专业的技术应用团队，专业聚焦机器视觉、传感器与人工智能领域软硬件产品开发，为客户企业提升生产和管理效率，促进企业自动化、智能化、数字化水平提升，提高企业的软硬件数字智能竞争力。
        </p>
        <p>
          唯视智能Heyfocus致力于打造具有核心竞争力的世界一流工业机器视觉软硬件及系统集成全系列产品，为自动化制造领域提供最优秀的机器视觉算法软件、高精度工业3D相机、高性能工业读码器以及工业智能化改造的全套解决方案。
        </p>
        <p>
          公司旨在通过更优秀的产品帮助企业提高产品质量、消除生产错误、降低制造成本、提供低廉的高质量产品从而超越消费者期望，面向以电子半导体，汽车零部件，物流及仓储，消费包装、工程机械等数十个行业的广大市场。
        </p>
        <p>
          公司拥有一支具有20年工业机器视觉市场营销经验的优秀团队，并且和世界500强企业签署战略合作协议，在市场营销方面形成核心竞争力；公司创始的研发团队全部来自世界500强企业，具有15年以上工业机器视觉和医疗影像设备产品开发的经验，并且形成自身的技术门槛，在产品方面具有核心竞争力。
        </p>
      </section>
      <section className={styles.section} id="about-1">
        <SectionProgress />
      </section>
      <section className={styles.section} id="about-2">
        <SectionCulture />
      </section>
      <section className={styles.section} id="about-3">
        <SectionProductLayout />
      </section>
      <section className={styles.section} id="about-4">
        <SectionCoreTeam />
      </section>
      {/* <section className={styles.section} id="about-5">
        <SectionHonors />
      </section> */}
    </DefaultLayout>
  );
};

export default AboutPage;

export const Head = () => <HfSeo title="关于我们 | 唯视智能" />;
