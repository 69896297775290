import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';
import techTeamImage from '../../images/about-team-1.jpg';
import serviceTeamImage from '../../images/about-team-2.jpg';

type PropTypes = {};
const DESCRIPTION =
  '唯视智能拥有优秀的科学家团队和卓越的市场营销团队，将机器学习、深度学习、机器视觉等技术带到生产线。我们的产品围绕自动化领域的诸多场景，结合团队积淀多年的算法和销售管理经验，将人工智能“植入”到自动化化行业的各个环节，以提升生产效率。';
const CARDS_DATA = [
  {
    imageUrl: techTeamImage,
    title: '技术团队简介',
    content:
      '唯视智能拥有优秀的科学家团队，将以计算视觉、深度学习、图像量化分析等机器视觉技术为核心的智能化硬件产品带到制造工业。研发团队拥有基于机器视觉算法智能相机的光机电算法软件全链路开发优势，成功开发数字内窥镜、工业扫码器等业内最优秀产品的研发经验。同时针对轮胎缺陷检测、焊缝检测，PCB电路短路断路检测等行业拥有成熟的算法开发经验。',
  },
  {
    imageUrl: serviceTeamImage,
    title: '服务团队简介',
    content:
      '唯视将以战略合作伙伴客户、丰富的实际应用场景、强大的技术应用、销售团队和支持团队快速推广并孵化产品。早期充分利用合伙伙伴强大客户资源和经销商渠道资源推动营收增长。把合作伙伴强大客户资源和经销渠道、专业团队及销售能力与技术支持能力、研发专家的算法优势和自有技术等三方面资源有效快速融合的推动模式。',
  },
];

export default function SectionCoreTeam({}: PropTypes) {
  return (
    <section className={styles.section}>
      <HfTitle className={styles.title} title="CORE TEAM" subTitle="核心团队" />
      <p className={styles.description}>{DESCRIPTION}</p>
      {CARDS_DATA.map((card, index) => {
        const isOdd = index % 2 === 1;
        return (
          <div className={clsx(styles.card, isOdd && styles.reverse)}>
            <div className={styles.image}>
              <img src={card.imageUrl} alt="" />
            </div>
            <div className={styles.message}>
              <div>
                <div className={styles.messageTitle}>{card.title}</div>
                <div className={styles.content}>{card.content}</div>
              </div>
            </div>
            <div className={styles.decorationBg} />
            <div className={styles.decorationText}>
              {isOdd ? (
                <div>
                  SERVICE
                  <br />
                  TEAM
                </div>
              ) : (
                <div>
                  TECHNICAL
                  <br />
                  TEAM
                </div>
              )}
            </div>
          </div>
        );
      })}
    </section>
  );
}
