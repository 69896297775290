// extracted by mini-css-extract-plugin
export var card = "index-module--card--w5QxM";
export var decorationBg = "index-module--decoration-bg--6b3RI";
export var decorationText = "index-module--decoration-text--89Jbc";
export var description = "index-module--description--3OZIM";
export var image = "index-module--image--FB8Pg";
export var message = "index-module--message--5Fy4S";
export var messageTitle = "index-module--message-title--hemiJ";
export var reverse = "index-module--reverse--Zlpsd";
export var section = "index-module--section--bpVCe";
export var title = "index-module--title--UBQdm";