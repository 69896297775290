import useScroll from 'ahooks/lib/useScroll';
import {Col, Row, Affix} from 'antd';
import clsx from 'clsx';
import {Link} from 'gatsby';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {GAP_SIZE_2, GAP_SIZE_3, GAP_SIZE_BASE} from '../../constants';
import * as styles from './index.module.scss';

type PropTypes = {
  tabs: {
    title: string;
    anchor?: string;
  }[];
};
/* 判定活跃 tab 时添加的偏移量 */
const ANCHOR_OFFSET = 1;
const isSSR = typeof window === 'undefined';

/**
 * 文章目录
 */
export default function HfArticleToc({tabs}: PropTypes) {
  const activeTab = useActiveTab(tabs);
  return (
    <Affix target={() => (isSSR ? null : document.body)}>
      <div className={styles.toc}>
        <Row
          className={styles.content}
          align="middle"
          gutter={[GAP_SIZE_BASE, GAP_SIZE_BASE]}
        >
          {tabs.map((tab, index) => (
            <Col key={tab.title}>
              <Link
                className={clsx(
                  styles.link,
                  index === activeTab && styles.active
                )}
                to={'#' + tab.anchor || '#'}
              >
                {tab.title}
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </Affix>
  );
}

function useActiveTab(tabs: PropTypes['tabs']): number | null {
  if (isSSR) return null;
  const [activeTab, setActiveTab] = useState<null | number>(null);
  const [tabsPosition, setTabsPosition] = useState<(null | number)[]>([]);
  const scroll = useScroll(document.body);
  useLayoutEffect(() => {
    const newTabsPosition = tabs.map(tab => {
      if (!tab.anchor) return null;
      const element = document.getElementById(tab.anchor);
      if (!element) return null;
      return element.getBoundingClientRect().top;
    });
    setTabsPosition(newTabsPosition);
  }, []);
  useEffect(() => {
    if (!scroll) return;
    for (let index = tabs.length - 1; index >= 0; index -= 1) {
      if (!tabsPosition[index]) continue;
      if (tabsPosition[index] - ANCHOR_OFFSET <= scroll.top) {
        setActiveTab(index);
        break;
      }
      setActiveTab(null);
    }
  }, [scroll, tabs]);
  return activeTab;
}
